import React, { useState, useRef } from "react";
import useScript from "hooks/useScript";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Loading from "components/loading/Loading";
import Blank from "components/blank/Blank";
import Chat from "components/chat/Chat";
import "./css/create-stream.module.css";
// import { withRoom } from "context";

// imgs
import COPY_ICON from "imgs/Copy.svg";

// libs
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import StreamHeader from "./StreamHeader";

function Stream(props) {
	const { flashphoner: Flashphoner, back, streamInfo } = props;
	const history = useHistory();
	const [status, setStatus] = useState("");
	const stream = useRef(null);
	const localVideoRef = useRef(null);
	const { t } = useTranslation();
	const { SESSION_STATUS } = Flashphoner.constants;
	const { STREAM_STATUS } = Flashphoner.constants;
	const watchUrl = `${window.origin}/${props?.streamInfo?.watchToken}`;

	const extractVideoTrack = (localVideo) => {
		return localVideo.firstChild.srcObject.getVideoTracks()[0];
	};

	const removeVideoTrackEndedListener = (localVideo) => {
		const videoTrack = extractVideoTrack(localVideo);
		if (videoTrack) {
			videoTrack.onended = null;
		}
	};

	const removeVideoElement = (video) => {
		stream.current.stop();
		if (video.srcObject) {
			video.pause();
			video.srcObject.getTracks().forEach((track) => {
				// eslint-disable-next-line no-param-reassign
				track.enabled = false;
				track.stop();
				video.srcObject.removeTrack(track);
			});

			// eslint-disable-next-line no-param-reassign
			video.srcObject = null;

			const element = localVideoRef.current;
			while (element.firstChild) {
				element.removeChild(element.firstChild);
			}

			history.go(0);
		}
	};

	const stopStream = () => {
		removeVideoTrackEndedListener(localVideoRef.current);
		removeVideoElement(localVideoRef.current.firstChild);
		// session.disconnect();
	};

	const addVideoTrackEndedListener = (localVideo) => {
		const videoTrack = extractVideoTrack(localVideo);
		if (videoTrack && videoTrack.readyState === "ended") {
			console.error("Video source error. Disconnect...");
			stopStream();
		} else if (videoTrack) {
			videoTrack.onended = (event) => {
				console.error("Video source error. Disconnect...", event);
				stopStream();
			};
		}
	};

	const startStreaming = (session) => {
		try {
			const { connectURL, connectName } = props.streamInfo;

			const localVideo = localVideoRef.current;
			session
				.createStream({
					name: connectName,
					display: localVideo,
					cacheLocalResources: true,
					receiveVideo: false,
					receiveAudio: false,
					rtmpUrl: connectURL,
					disableConstraintsNormalization: true,
					constraints: {
						video: {
							width: { min: 1280, max: 1920 },
							height: { min: 720, max: 1080 },
							frameRate: { max: 30 },
						},
						audio: true,
					},
					// constraints: {
					// 	video: {
					// 		width: { ideal: 1920 },
					// 		height: { ideal: 1080 },
					// 	},
					// 	audio: true,
					// },
				})
				.on(STREAM_STATUS.PUBLISHING, (publishStream) => {
					setStatus(STREAM_STATUS.PUBLISHING);

					// for stream stop
					stream.current = publishStream;
					addVideoTrackEndedListener(localVideo);
				})
				.on(STREAM_STATUS.UNPUBLISHED, () => {
					setStatus(STREAM_STATUS.UNPUBLISHED);
				})
				.on(STREAM_STATUS.FAILED, () => {
					setStatus(STREAM_STATUS.FAILED);
				})
				.publish();
		} catch (error) {
			console.log(error);
		}
	};

	const publishToYoutube = () => {
		const urlServer = "wss://main.pphndc.com:8443";
		Flashphoner.init();
		Flashphoner.createSession({ urlServer })
			.on(SESSION_STATUS.ESTABLISHED, (session) => {
				setStatus(session.status());
				startStreaming(session);
			})
			.on(SESSION_STATUS.DISCONNECTED, () => {
				setStatus("Connection DISCONNECTED");
			})
			.on(SESSION_STATUS.FAILED, () => {
				setStatus("Connection FAILED");
			});
	};

	return (
		<div>
			<Blank backBtnHandler={back} hidden={status === "PUBLISHING"}>
				<div styleName="blank-content webcam-content">
					<div className="title-5 mb_16">
						{t("StartBroadcast.Webcam.Title")}
					</div>
					<div className="text-1 c_base60 mb_24">
						{t("StartBroadcast.Webcam.Description")}
						<div className="c_prim100 mb_24">{watchUrl}</div>
						<CopyToClipboard text={watchUrl} onCopy={() => alert("Copied!")}>
							<span
								className="btn_gray btn_with-icon"
								style={{ backgroundImage: `url(${COPY_ICON})` }}
							>
								{t("Button.CopyLink")}
							</span>
						</CopyToClipboard>
					</div>
					<button
						className="btn"
						styleName="start-btn"
						hidden={status === "PUBLISHING"}
						type="button"
						onClick={publishToYoutube}
					>
						{t("StartBroadcast.Webcam.ButtonStart")}
					</button>
				</div>
			</Blank>
			<div styleName="broadcasting" hidden={status !== "PUBLISHING"}>
				{/* <StreamHeader user={streamInfo.userId} /> */}
				<div styleName="content">
					<div styleName="video-preview" ref={localVideoRef} />
					<Chat
						liveChatID={streamInfo.liveChatID}
						playerId={streamInfo.playerID}
					/>
				</div>
				<button className="btn" type="button" onClick={stopStream}>
					{t("StartBroadcast.Webcam.ButtonEnd")}
				</button>
				{/* <p>{status}</p> */}
			</div>
		</div>
	);
}

// const StreamWithContext = withRoom(Stream);

Stream.propTypes = {
	streamInfo: PropTypes.objectOf(PropTypes.string).isRequired,
	flashphoner: PropTypes.objectOf(PropTypes.any).isRequired,
	back: PropTypes.func.isRequired,
};

export default function FlashphonerProvider(props) {
	const [scriptLoaded, setScriptLoaded] = useState(false);

	useScript("/js/flashphoner.min.js", () => setScriptLoaded(true));

	return scriptLoaded ? (
		<Stream {...props} flashphoner={window.Flashphoner} />
	) : (
		<Loading fullWidth />
	);
}
