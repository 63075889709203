import React, { useState, useEffect, useRef } from "react";
import Loading from "components/loading/Loading";
import useScript from "hooks/useScript";
import "../css/chat.module.css";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Message from "./Message";
import generateRandomMsg from "../utils/generateRandomMsg";

function ApiChat(props) {
	const { gapi, liveChatID } = props;
	const [messages, setMessages] = useState([]);
	const [newItems, setNewItems] = useState([]);
	const [messageText, setMessageText] = useState("");
	const [loggedGuser, setLoggedGuser] = useState(false);
	const [chatTimer, setChatTimer] = useState(false);
	const [allowScrollToBottom, setAllowScrollToBottom] = useState(true);
	const messagesListRef = useRef(null);
	const nextPageToken = useRef(null);
	const auth2 = useRef(null);
	const { t } = useTranslation();

	const authenticate = () => {
		return gapi.auth2
			.getAuthInstance()
			.signIn({ scope: "https://www.googleapis.com/auth/youtube" })
			.then(
				() => {
					// console.log("Sign-in successful");
					setLoggedGuser(true);
				},
				(err) => {
					console.error("Error signing in", err);
				}
			);
	};

	const getMessages = () => {
		const reqOptions = {
			liveChatId: liveChatID,
			part: "snippet,authorDetails",
			pageToken: nextPageToken.current,
		};
		return gapi.client.youtube.liveChatMessages.list(reqOptions).then(
			(response) => {
				// console.log("Response", response);
				setNewItems(response.result.items);
				nextPageToken.current = response.result.nextPageToken;
				if (!chatTimer) setChatTimer(response.result.pollingIntervalMillis);
			},
			(err) => {
				console.error("Execute error", err);
			}
		);

		// // console.log("new message");
		// setNewItems([generateRandomMsg()]);
		// if (!chatTimer) setChatTimer(1000);
	};

	const loadClient = () => {
		gapi.client.setApiKey(process.env.REACT_APP_GAPP_KEY);

		return gapi.client
			.load("https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest")
			.then(
				() => {
					// console.log("GAPI client loaded for API");
					getMessages();
				},
				(err) => {
					console.error("Error loading GAPI client for API", err);
				}
			);
	};

	const checkForGuser = () => {
		if (auth2.current.isSignedIn.get() === true) {
			setLoggedGuser(true);
			return true;
		}
		setLoggedGuser(false);
		return false;
	};

	const sendMessage = () => {
		return gapi.client.youtube.liveChatMessages
			.insert({
				part: "snippet",
				resource: {
					snippet: {
						liveChatId: liveChatID,
						type: "textMessageEvent",
						textMessageDetails: {
							messageText,
						},
					},
				},
			})
			.then(
				(response) => {
					// console.log("Response", response);
					setMessageText("");
				},
				(err) => {
					console.error("Execute error", err);
				}
			);
	};

	const preventScrollToBottom = () => {
		const elem = messagesListRef.current;
		const cond =
			Math.abs(elem.scrollHeight - elem.offsetHeight - elem.scrollTop) > 10;
		if (cond) setAllowScrollToBottom(false);
		else if (!allowScrollToBottom && !cond) setAllowScrollToBottom(true);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (chatTimer) getMessages();
		}, chatTimer || 5024);

		return () => clearTimeout(timer);
	});

	useEffect(() => {
		setMessages([...messages, ...newItems]);
	}, [newItems]);

	useEffect(() => {
		gapi.load("client:auth2", async () => {
			auth2.current = await gapi.auth2.init({
				client_id: process.env.REACT_APP_GCLIENT_ID,
			});
			checkForGuser();
			loadClient();
		});
	}, []);

	// var signinChanged = function (val) {
	// 	console.log("Signin state changed to ", val);
	// };

	// var onSuccess = function (user) {
	// 	console.log(`Signed in as ${user.getBasicProfile().getName()}`);
	// 	// Redirect somewhere
	// };

	// var onFailure = function (error) {
	// 	console.log(error);
	// };

	// function signOut() {
	// 	gapi.auth2.signOut().then(function () {
	// 		console.log("User signed out.");
	// 	});
	// }

	// const userChanged = function (user) {
	// 	console.log(user);
	// 	if (user.getId()) {
	// 		// Do something here
	// 	}
	// };

	useEffect(() => {
		if (
			allowScrollToBottom &&
			messagesListRef.current &&
			messagesListRef.current.lastChild
		) {
			const list = messagesListRef.current;
			list.scrollTop = list.scrollHeight;
		}
	}, [messages]);

	return (
		<div styleName="chat">
			<div styleName="chat-snippet">
				<div styleName="header" className="text-2">
					{t("WatchStream.Chat.Header.Label")}
				</div>
				<div styleName="messages-container">
					<div
						styleName="messages"
						ref={messagesListRef}
						onScroll={() => preventScrollToBottom()}
					>
						{messages.map((message) => (
							<Message key={message.etag} message={message} />
						))}
					</div>
				</div>
				{loggedGuser ? (
					<div styleName="input-container">
						<input
							styleName="input"
							className="text-1"
							placeholder={t("Thread.Item.Msg.Status.New")}
							value={messageText}
							onKeyDown={(event) => {
								if (event.keyCode === 13) sendMessage();
							}}
							onChange={(event) => setMessageText(event.target.value)}
						/>
						<button
							aria-label={t("Common.Send")}
							type="button"
							styleName="submit-btn"
							className="icon_sm"
							onClick={sendMessage}
						/>
					</div>
				) : (
					<div styleName="login-to-chat-btn">
						<Button
							variant="outlined"
							color="primary"
							type="button"
							onClick={authenticate}
						>
							{t("WatchStream.Chat.LoginSnippet")}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}

ApiChat.propTypes = {
	gapi: PropTypes.objectOf(PropTypes.any).isRequired,
	liveChatID: PropTypes.string.isRequired,
};

export default function GProvider(props) {
	const [scriptLoaded, setScriptLoaded] = useState(false);

	useScript("https://apis.google.com/js/api.js", () => {
		setScriptLoaded(true);
	});

	return scriptLoaded ? (
		<ApiChat {...props} gapi={window.gapi} />
	) : (
		<Loading fullWidth />
	);
}
