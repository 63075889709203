import React, { useState, useEffect, useRef } from "react";
import useScript from "hooks/useScript";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Loading from "components/loading/Loading";
import "../css/chat.module.css";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function IframeChat(props) {
	const { liveChatID, playerId, gapi } = props;
	const [loggedGuser, setLoggedGuser] = useState(false);
	const { host } = window.location;
	const { t } = useTranslation();
	const history = useHistory();
	const auth2 = useRef(null);

	const authenticate = () => {
		return gapi.auth2
			.getAuthInstance()
			.signIn({ scope: "https://www.googleapis.com/auth/youtube" })
			.then(
				() => {
					console.log("Sign-in successful");
					// setLoggedGuser(true);
					history.go(0);
				},
				(err) => {
					console.error("Error signing in", err);
				}
			);
	};

	const checkForGuser = () => {
		if (auth2.current.isSignedIn.get() === true) {
			console.log("+");
			setLoggedGuser(true);
			return true;
		}
		console.log("-");
		setLoggedGuser(false);
		return false;
	};

	useEffect(() => {
		gapi.load("client:auth2", async () => {
			auth2.current = await gapi.auth2.init({
				client_id: process.env.REACT_APP_GCLIENT_ID,
			});
			checkForGuser();
			// loadClient();
		});
	}, []);

	return liveChatID ? (
		<div styleName="iframe-chat">
			<iframe
				styleName="iframe"
				title="chat"
				allowFullScreen=""
				frameBorder="0"
				// height="500"
				src={`https://www.youtube.com/live_chat?v=${playerId}&embed_domain=${host}`}
				// width="600"
			/>
			{!loggedGuser && (
				<div styleName="login-section">
					<div styleName="login-to-chat-btn">
						<Button
							variant="outlined"
							color="primary"
							type="button"
							onClick={authenticate}
						>
							{t("WatchStream.Chat.LoginSnippet")}
						</Button>
					</div>
				</div>
			)}
		</div>
	) : null;
}

IframeChat.propTypes = {
	liveChatID: PropTypes.string.isRequired,
	playerId: PropTypes.string.isRequired,
	gapi: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default function GProvider(props) {
	const [scriptLoaded, setScriptLoaded] = useState(false);

	useScript("https://apis.google.com/js/api.js", () => {
		setScriptLoaded(true);
	});

	return scriptLoaded ? (
		<IframeChat {...props} gapi={window.gapi} />
	) : (
		<Loading fullWidth />
	);
}
