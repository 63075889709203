import React, { useEffect, useState } from "react";
import { API } from "functions";
import Loading from "components/loading/Loading";
// import Snackbar, { showModal } from "components/snackbar/Snackbar";
import WebcamStream from "./WebcamStream";
import EncoderStream from "./EncoderStream";
import CreateRoomSection from "./CreateRoomSection";
import "./css/create-stream.module.css";

export default function CreateRoom(props) {
	const startToken = props?.match?.params?.token;
	const [mode, setMode] = useState("loading");
	const [streamInfo, setStreamInfo] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await API.get(`start-stream/${startToken}`);
				setStreamInfo(response.data);
				setMode("create");
			} catch (error) {
				console.log(error);
				// const [show] = Snackbar({ text: "222" });
				// showModal(true, { text: "222" });
				alert("Error! Invalid start token");
			}
		};
		fetchData();
	}, [startToken]);

	return (
		<div styleName="create-room">
			{/* <Snackbar
				isOpen
				text="test 2222"
				// handleClose={() => console.log("close")}
			/> */}
			{(() => {
				switch (mode) {
					case "loading":
						return <Loading fullWidth />;
					case "create":
						return (
							<CreateRoomSection setMode={(newMode) => setMode(newMode)} />
						);
					case "webcam":
						return (
							<WebcamStream
								streamInfo={streamInfo}
								back={() => setMode("create")}
							/>
						);
					case "encoder":
						return (
							<EncoderStream
								streamInfo={streamInfo}
								back={() => setMode("create")}
							/>
						);
					default:
						return null;
				}
			})()}
		</div>
	);
}
