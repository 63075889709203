import React from "react";

export default function Home() {
	return (
		<div>
			There are no availiable streams now :(
			<br />
			But you can get in conntact with anyone in
			<a href="https://payphone.io/">Payphone X</a>
		</div>
	);
}
